<template>
    <div class="header"
         :style="{
      background: 'url('+headTop+') no-repeat',
      backgroundSize: '100%',
    }">
        <div class="go-home" @click="goHome">回到首页</div>
        <div class="navMenu">
            <div style="margin-left: -2rem;">
                <img class="item" @click="loginHeader" :src="login" />
                <img class="item" @click="registerHeader" :src="register" />
            </div>
            <div style="margin-left: 0rem;">
                <img class="item" :src="meiRiDaKa"  />
                <img class="item" :src="meiRiJiFen" @click="showMeiRiJiFen" />
            </div>
        </div>
        <Login v-show="vuex_login_member_show"></Login>
        <div style="position: absolute;top:0vh;left:20vw;width:60vw;height:100vh;cursor: pointer;" @click="isShowIntegral=false" v-if="isShowIntegral">
            <Integral />
        </div>
    </div>
</template>

<script>
    import store from '@/store/index';
    import Login from "@/components/Login.vue";
    import Integral from "../../enterprise/Integral";
    export default {
        components:{Login,Integral},
        data(){
            return {
                headTop:require("../../../assets/images/ai_bai_bao_xiang/head_top.webp"),
                login:require("../../../assets/images/ai_bai_bao_xiang/login.png"),
                register:require("../../../assets/images/ai_bai_bao_xiang/register.png"),
                meiRiDaKa:require("../../../assets/images/ai_bai_bao_xiang/mei_ri_da_ka.png"),
                meiRiJiFen:require("../../../assets/images/ai_bai_bao_xiang/mei_ri_ji_fen.png"),
                memberType: "login",
                isShowIntegral:false, // 是否显示积分界面
            }
        },
        methods:{
            goHome() {
                this.$router.push("/");
            },
            loginHeader(){
                store.commit('$kuStore', {
                    name: 'vuex_login_member_type', value: 'login'
                });
                store.commit('$kuStore', {
                    name: 'vuex_login_member_show', value: true
                });
            },
            registerHeader(){
                store.commit('$kuStore', {
                    name: 'vuex_login_member_type', value: 'register'
                });
                store.commit('$kuStore', {
                    name: 'vuex_login_member_show', value: true
                });
            },
            showMeiRiJiFen(){
                console.log("显示每日积分");
                this.isShowIntegral=true;
            }
        }
    }
</script>

<style lang="less" scoped>
    .header{
        .go-home{
            position: absolute;
            top:1vh;
            left: 1vw;
            color:#fff793;
            cursor: pointer;
        }
        height: 213px;
        display: flex;
        align-items: end;
        .navMenu{
            height: 52px;
            width: 100%;
            margin-bottom: 33px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .item{
                width: auto;
                &:nth-child(2){
                    margin-left: 43px;
                }

                cursor: pointer;
                background-color: rgba(0,0,0,0);
            }

        }

    }
</style>
