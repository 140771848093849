<template>
    <div>
        <Header />
        <Content />
    </div>
</template>

<script>
    import Header from "./components/header";
    import Content from "./components/content";
    export default {
        components: { Header,Content},
        data() {
            return {
            };
        },
    }
</script>

<style lang="less" scoped>

</style>
