<template>
    <div class="content">
        <div class="column">
            <div class="tab">
                <div class="tag">
                    <img :src="guo_nei_ai_gong_ju_tab" />
                </div>
                <div class="tabContent">
                    <div class="left">
                        <div class="item">
                            <img :src="chat_gpt"/>
                            <label>ChatGPT</label>
                        </div>
                        <div class="item">
                            <img :src="midjourney" />
                            <label>Midjourney</label>
                        </div>
                        <div class="item">
                            <img :src="notion" />
                            <label>Notion</label>
                        </div>
                        <div class="item">
                            <img :src="stable_diffusion" />
                            <label>stable diffusion</label>
                        </div>
                    </div>
                    <div class="right">
                        <img :src="wo_yao_xue_ai" />
                    </div>
                </div>
            </div>
            <div class="tab">
                <div class="tag">
                    <img :src="guo_nei_ai_gong_ju_tab" />
                </div>
                <div class="tabContent">
                    <div class="left">
                        <div class="item">
                            <img :src="hua_wei"/>
                            <label>盘古大模型</label>
                        </div>
                        <div class="item">
                            <img :src="wu_jie_ai"/>
                            <label>无界 AI</label>
                        </div>
                    </div>
                    <div class="right">
                        <img :src="wo_yao_xue_ai" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                guo_nei_ai_gong_ju_tab:require("../../../assets/images/ai_bai_bao_xiang/guo_nei_ai_gong_ju_tab.png"),
                chat_gpt:require("../../../assets/images/ai_bai_bao_xiang/chat_gpt.png"),
                midjourney:require("../../../assets/images/ai_bai_bao_xiang/midjourney.png"),
                notion:require("../../../assets/images/ai_bai_bao_xiang/notion.png"),
                stable_diffusion:require("../../../assets/images/ai_bai_bao_xiang/stable_diffusion.png"),
                wo_yao_xue_ai:require("../../../assets/images/ai_bai_bao_xiang/wo_yao_xue_ai.png"),
                hua_wei:require("../../../assets/images/ai_bai_bao_xiang/hua_wei.png"),
                wu_jie_ai:require("../../../assets/images/ai_bai_bao_xiang/wu_jie_ai.png"),

            }
        }
    }
</script>

<style scoped lang="less">
    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        .column{
            width: 75%;
            .tab{
                .tag{
                    display: flex;
                    justify-content: start;
                }
                .tabContent{
                    margin-top: 55px;
                    display: flex;
                    justify-content: space-between;
                    .left{
                        display: flex;
                        justify-content: start;
                        img{
                            width: 129px;
                            height:129px;
                        }
                        .item{
                            display: flex;
                            flex-direction: column;
                            margin-left: 46px;
                            label{
                                color: white;
                                font-weight: bolder;
                                margin-top: 10px;
                            }
                        }
                    }
                    .right{
                        img{
                            cursor: pointer;
                        }
                    }
                }
            }
            .tab:nth-child(2){
                margin-top: 79px;
            }
        }
    }
</style>
